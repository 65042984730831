import { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { Dialog, Transition } from "@headlessui/react";
import { doc } from "firebase/firestore";
import { useAuth } from "context/AuthContext";
import { useDocument } from "react-firebase-hooks/firestore";
import { db, useDocumentOnceWithDependencies, storage } from "utils/firebase";
import { toast } from "react-toastify";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { getInitials } from "utils/textUtils";
import { addPost, updatePost } from "pages/api/post";
import StandardModal from "./StandardModal";
import ExpandedModal from "./ExpandedModal";
import Unauthenticated from "../common/Unauthenticated";
import { updateUserLastPost } from "pages/api/user";
import usePremiumStatus from "hooks/app/usePremiumStatus";
import { objectUrlToFile } from "utils/imageUtils";

interface AddPostModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function AddPostModal({ open, setOpen }: AddPostModalProps) {
  const { user } = useAuth();
  const router = useRouter();

  const userIsPremium = usePremiumStatus(user);
  const editId = router?.query?.editId?.toString() || "0";

  const isEditMode = editId !== "0";

  const textAreaRef = useRef(null);
  const articleTitleRef = useRef(null);

  const [content, setContent] = useState("");
  const [isTextContentUploading, setIsTextContentUploading] = useState(false);
  const [isArticleUploading, setIsArticleUploading] = useState(false);
  const [isAudioUploading, setAudioUploading] = useState(false);
  const [isVideoUploading, setVideoUploading] = useState(false);
  const [isImageUploading, setImageUploading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [userProfile] = useDocumentOnceWithDependencies(
    user?.uid ? doc(db, `users`, user?.uid) : null,
    [user?.uid]
  );

  const [editPost, editPostDataLoading] = useDocument(doc(db, "posts", editId));
  const editPostData = editPost?.data();

  useEffect(() => {
    if (
      router?.query?.post === "article" ||
      (editPost?.exists() && editPostData?.type.includes("article"))
    )
      setIsExpanded(true);
    else if (
      router?.query?.post === "standard" ||
      (editPost?.exists() && editPostData?.type.includes("short"))
    )
      setIsExpanded(false);
  }, [editPost, editPostData?.type, router]);

  const getExpandDialogContainerStyles = () => (isExpanded ? "p-0" : "p-4");

  const getExpandDialogStyles = () =>
    isExpanded
      ? "w-screen h-screen"
      : "w-full max-w-3xl mt-16 h-full max-h-[calc(100vh-100px)] overflow-auto";

  const handleStandardPost = useCallback(
    async (
      textContent: string,
      audioContent: File,
      videoContent: File,
      imageContent: File
    ) => {
      if (isTextContentUploading || isAudioUploading || isVideoUploading)
        return;
      setIsTextContentUploading(true);

      const types = ["short"];

      if (textContent) types.push("text");
      if (audioContent) {
        types.push("audio");
        setAudioUploading(true);
      }
      if (videoContent) {
        types.push("video");
        setVideoUploading(true);
      }
      if (imageContent) {
        types.push("image");
        setImageUploading(true);
      }

      return await updatePost({
        docId: editId || "",
        details: {
          authorId: user?.uid,
          type: types,
          shortTextContent: textContent || "",
          articleContent: "",
          articleTitle: "",
          audioSrc: "",
          videoSrc: "",
          imageSrc: "",
        },
        isNewVersion: isEditMode,
      })
        .then(async (docId) => {
          setIsTextContentUploading(false);
          setContent("");

          await updateUserLastPost({ docId: user?.uid || "" });

          if (!imageContent && !audioContent && !videoContent) {
            if (editId !== "0") router.push(`/posts/${docId}`);
            setOpen(false);
          }

          if (audioContent) {
            const audioStorageRef = ref(storage, `posts/${docId}/audio`);

            const audioUploadTask = uploadBytesResumable(
              audioStorageRef,
              audioContent
            );

            await audioUploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                toast.error("There was a problem uploading your audio.");
                setAudioUploading(false);
                setIsTextContentUploading(false);
              },
              () => {
                getDownloadURL(audioUploadTask.snapshot.ref).then(
                  (downloadURL) => {
                    updatePost({
                      docId,
                      details: {
                        audioSrc: downloadURL,
                      },
                    })
                      .then(() => {
                        setAudioUploading(false);
                        if (
                          !isTextContentUploading &&
                          !isVideoUploading &&
                          !isImageUploading
                        ) {
                          if (editId !== "0") router.push(`/posts/${docId}`);
                          setOpen(false);
                        }
                      })
                      .catch((err) => {
                        setAudioUploading(false);
                        toast.error("Something went wrong");
                        console.error(err);
                      });
                  }
                );
              }
            );
          }

          if (videoContent) {
            const videoStorageRef = ref(storage, `posts/${docId}/video`);

            const videoUploadTask = uploadBytesResumable(
              videoStorageRef,
              videoContent
            );

            await videoUploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                toast.error("There was a problem uploading your video.");
                setVideoUploading(false);
                setIsTextContentUploading(false);
              },
              () => {
                getDownloadURL(videoUploadTask.snapshot.ref).then(
                  (downloadURL) => {
                    updatePost({
                      docId,
                      details: {
                        videoSrc: downloadURL,
                      },
                    })
                      .then(() => {
                        setVideoUploading(false);
                        if (
                          !isTextContentUploading &&
                          !isAudioUploading &&
                          !isImageUploading
                        ) {
                          if (editId !== "0") router.push(`/posts/${docId}`);
                          setOpen(false);
                        }
                      })
                      .catch((err) => {
                        setVideoUploading(false);
                        toast.error("Something went wrong");
                        console.error(err);
                      });
                  }
                );
              }
            );
          }

          if (imageContent) {
            const imageStorageRef = ref(storage, `posts/${docId}/image`);

            const imageUploadTask = uploadBytesResumable(
              imageStorageRef,
              imageContent
            );

            await imageUploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                console.log("error", error);
                toast.error("There was a problem uploading your image.");
                setImageUploading(false);
                setIsTextContentUploading(false);
              },
              () => {
                getDownloadURL(imageUploadTask.snapshot.ref).then(
                  (downloadURL) => {
                    updatePost({
                      docId,
                      details: {
                        imageSrc: downloadURL,
                      },
                    })
                      .then(() => {
                        setImageUploading(false);
                        if (
                          !isTextContentUploading &&
                          !isAudioUploading &&
                          !isVideoUploading
                        ) {
                          if (editId !== "0") router.push(`/posts/${docId}`);
                          setOpen(false);
                        }
                      })
                      .catch((err) => {
                        setImageUploading(false);
                        toast.error("Something went wrong");
                        console.error(err);
                      });
                  }
                );
              }
            );
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
          setIsTextContentUploading(false);
          setAudioUploading(false);
          setVideoUploading(false);
          setImageUploading(false);
          console.error(err);
        });
    },
    [
      isTextContentUploading,
      isAudioUploading,
      isVideoUploading,
      editId,
      user?.uid,
      isEditMode,
      router,
      setOpen,
      isImageUploading,
    ]
  );

  const handleArticlePost = useCallback(
    async (articleContent: string, articleTitle?: string) => {
      if (isArticleUploading) return;
      setIsArticleUploading(true);

      await updatePost({
        docId: editId || "",
        details: {
          authorId: user?.uid || "",
          type: ["text", "article"],
          articleContent,
          articleTitle: articleTitle || "",
          shortTextContent: "",
          audioSrc: "",
          videoSrc: "",
          imageSrc: "",
        },
        isNewVersion: isEditMode,
      })
        .then(async (docId) => {
          await updateUserLastPost({ docId: user?.uid || "" });

          // If editor has images, upload to storage and replace image srcs
          if (
            articleContent.includes(
              `"type":"image","data":{"file":{"url":"blob:`
            )
          ) {
            const articleImages = articleContent.match(
              /"type":"image","data":{"file":{"url":"blob:(.*?)"/g
            );

            const articleImagesDownloadURLs = await Promise.all(
              articleImages.map(async (image, index) => {
                const urlOnly = image.replace(
                  `"type":"image","data":{"file":{"url":"`,
                  ""
                );
                const cleanImage = urlOnly.replace(`"`, "");
                const uuid = uuidv4();

                const imageStorageRef = ref(
                  storage,
                  `posts/${docId}/article/${uuid}`
                );
                const imageBlob = await objectUrlToFile(cleanImage);

                const imageUploadTask = uploadBytesResumable(
                  imageStorageRef,
                  imageBlob as Blob
                );

                return new Promise<string>((resolve, reject) => {
                  imageUploadTask.on(
                    "state_changed",
                    (snapshot) => {},
                    (error) => {
                      console.log("error", error);
                      toast.error("There was a problem uploading your image.");
                      reject(error);
                    },
                    () => {
                      getDownloadURL(imageUploadTask.snapshot.ref).then(
                        (downloadURL) => {
                          resolve(downloadURL);
                        }
                      );
                    }
                  );
                });
              })
            );

            articleImages.forEach((image, index) => {
              articleContent = articleContent.replace(
                image,
                `"type":"image","data":{"file":{"url":"${articleImagesDownloadURLs[index]}"`
              );
            });

            await updatePost({ docId, details: { articleContent } }).then(
              () => {
                setIsArticleUploading(false);
                if (editId !== "0") router.push(`/posts/${docId}`);
                setOpen(false);
              }
            );
          } else {
            setIsArticleUploading(false);
            if (editId !== "0") router.push(`/posts/${docId}`);
            setOpen(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
          console.error(err);
          setIsArticleUploading(false);
          setOpen(false);
        });
    },
    [isArticleUploading, editId, user?.uid, isEditMode, router, setOpen]
  );

  const handleStandardTextContentChange = (textContent: string) =>
    setContent(textContent);

  const isStandardModalLoading =
    isTextContentUploading ||
    isAudioUploading ||
    isVideoUploading ||
    isImageUploading;

  const renderModalContent = useCallback(() => {
    const handleOpenEditorClick = () => {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, post: "article" },
      });
    };
    const handleModalClose = () => setOpen(false);

    const filteredExpandedEditPostData = {
      authorId: editPostData?.authorId,
      articleContent: editPostData?.articleContent,
      articleTitle: editPostData?.articleTitle,
    };

    const filteredStandardEditPostData = {
      authorId: editPostData?.authorId,
      shortTextContent: editPostData?.shortTextContent,
      audioSrc: editPostData?.audioSrc,
      videoSrc: editPostData?.videoSrc,
      imageSrc: editPostData?.imageSrc,
    };

    if (!user) return <Unauthenticated />;
    else if (isExpanded)
      return (
        <ExpandedModal
          editPostDataLoading={
            editId !== "0" &&
            !(editPostData?.type?.length !== 0) &&
            !editPostData?.articleContent
          }
          editPostData={filteredExpandedEditPostData}
          articleTitleRef={articleTitleRef}
          onCloseClick={handleModalClose}
          onPostClick={handleArticlePost}
          isLoading={isArticleUploading}
          defaultContent={content || editPostData?.shortTextContent}
        />
      );

    return (
      <StandardModal
        editPostData={filteredStandardEditPostData}
        textAreaRef={textAreaRef}
        onCloseClick={handleModalClose}
        onOpenEditorClick={handleOpenEditorClick}
        onTextContentChange={handleStandardTextContentChange}
        onPostClick={handleStandardPost}
        isLoading={isStandardModalLoading}
        userInitials={getInitials(user?.name)}
        userProfileImage={userProfile?.data()?.profileImageUrl}
      />
    );
    // articleContent and types cause infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editId,
    content,
    editPostData?.authorId,
    handleArticlePost,
    handleStandardPost,
    isArticleUploading,
    isExpanded,
    isStandardModalLoading,
    router,
    setOpen,
    user,
    userProfile,
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        initialFocus={isExpanded ? articleTitleRef : textAreaRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div
            className={`${getExpandDialogContainerStyles()} flex max-h-screen min-h-full w-screen items-center justify-center overflow-y-auto text-center sm:items-center`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${getExpandDialogStyles()} relative transform rounded-lg bg-white text-left shadow-xl transition-all`}
              >
                {renderModalContent()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddPostModal;
