import Image from "next/image";
interface AvatarProps {
  size?: "s" | "m" | "l" | "xl" | "3xl" | "4xl";
  initials?: string;
  imageUrl?: string | File | Blob;
  tint?: "light" | "dark";
  withBorder?: boolean;
}

function Avatar({
  size = "m",
  initials,
  imageUrl,
  tint,
  withBorder = false,
}: AvatarProps) {
  const getWidth = () => {
    if (size === "s") return "w-6";
    if (size === "l") return "w-12";
    if (size === "xl") return "w-16";
    if (size === "3xl") return "w-36";
    if (size === "4xl") return "w-[160px]";
    return "w-8";
  };

  const getHeight = () => {
    if (size === "s") return "h-6";
    if (size === "l") return "h-12";
    if (size === "xl") return "h-16";
    if (size === "3xl") return "h-36";
    if (size === "4xl") return "h-[160px]";
    return "h-8";
  };

  const getTextSize = () => {
    if (size === "s") return "text-xs";
    if (size === "l") return "text-lg";
    if (size === "xl") return "text-2xl";
    if (size === "3xl") return "text-3xl";
    if (size === "4xl") return "text-5xl";
    return "text-md";
  };

  const getInitialWidth = () => {
    if (size === "s") return 24;
    if (size === "l") return 48;
    if (size === "xl") return 64;
    if (size === "3xl") return 144;
    if (size === "4xl") return 160;
    return 32;
  };

  const getInitialHeight = () => {
    if (size === "s") return 24;
    if (size === "l") return 48;
    if (size === "xl") return 64;
    if (size === "3xl") return 144;
    if (size === "4xl") return 160;
    return 32;
  };

  const getTintColor = () => {
    if (tint === "dark") return "opacity-[0.05]";
    return "opacity-[0.02]";
  };

  const getBorderStyles = () => {
    if (withBorder) return "border-4 border-white";
    return "";
  };

  const renderAvatar = () => {
    if (imageUrl) {
      const fileImageUrl =
        typeof imageUrl === "string" ? imageUrl : URL.createObjectURL(imageUrl);

      return (
        <span
          className={`${getHeight()} ${getWidth()} relative box-border flex items-center justify-center rounded-full `}
        >
          <span
            className={`absolute ${getHeight()} ${getWidth()} ${getBorderStyles()} rounded-full bg-black ${getTintColor()}`}
          ></span>
          <Image
            referrerPolicy="no-referrer"
            className={`${getHeight()} ${getWidth()} ${getBorderStyles()} rounded-full bg-white object-cover`}
            src={fileImageUrl}
            alt="User Avatar"
            width={getInitialWidth()}
            height={getInitialHeight()}
          />
        </span>
      );
    } else if (initials) {
      return (
        <span
          className={`flex ${getHeight()} ${getWidth()} ${getBorderStyles()} flex-wrap content-center justify-center rounded-full bg-gray-300`}
        >
          <span
            className={`flex ${getWidth()}  justify-center ${getTextSize()} font-medium leading-none text-white`}
          >
            {initials}
          </span>
        </span>
      );
    }

    return (
      <div className="flex flex-wrap content-center items-start justify-start">
        <span
          className={`inline-block ${getHeight()} ${getWidth()} ${getBorderStyles()} overflow-hidden rounded-full bg-gray-100`}
        >
          <svg
            className={`${getHeight()} text-gray-300`}
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </div>
    );
  };

  return <>{renderAvatar()}</>;
}

export default Avatar;
